import request from '../utils/request'
import baseURL from '../utils/config'


//省市区街道
export function GaodeDistrict(params) {
  return request({
      url:'https://restapi.amap.com/v3/config/district?key=e3d9d41136d0582c5984ef030aad37eb',
      method: 'get',
      params
  })
}
//不可发货列表
export function GetShipmentsList(params) {
  return request({
      url: baseURL.baseURL + 'api/Adm_Order/GetShipmentsList',
      method: 'get',
      params
  })
}

//添加不可发货信息
export function AddShipments(data) {
  return request({
      url: baseURL.baseURL + 'api/Adm_Order/AddShipments',
      method: 'post',
      data
  })
}


//编辑不可发货信息
export function UpShipments(data) {
  return request({
      url: baseURL.baseURL + 'api/Adm_Order/UpShipments',
      method: 'post',
      data
  })
}



//不可发货平台列表
export function ListPlatform(params) {
  return request({
      url: baseURL.baseURL + 'api/Adm_Order/ListPlatform',
      method: 'get',
      params
  })
}



//添加不可发货平台
export function AddPlatform(data) {
  return request({
      url: baseURL.baseURL + 'api/Adm_Order/AddPlatform',
      method: 'post',
      data
  })
}

//编辑不可发货平台
export function UpPlatform(data) {
  return request({
      url: baseURL.baseURL + 'api/Adm_Order/UpPlatform',
      method: 'post',
      data
  })
}

//获取不可发货原因下拉菜单
export function GetForbidLogisticsList(params) {
  return request({
      url: baseURL.baseURL + 'api/Adm_Order/GetForbidLogisticsList',
      method: 'get',
      params
  })
}



//获取不可发货原因列表
export function GetForbidLogistics(params) {
  return request({
      url: baseURL.baseURL + 'api/Adm_Order/GetForbidLogistics',
      method: 'get',
      params
  })
}


//物流公司列表
export function GetLogisticsCompanyLists(params) {
  return request({
      url: baseURL.baseURL + 'api/Adm_Company/GetLogisticsCompanyLists',
      method: 'get',
      params
  })
}

//添加不可发货原因列表
export function AddForbidLogistics(data) {
  return request({
      url: baseURL.baseURL + 'api/Adm_Order/AddForbidLogistics',
      method: 'post',
      data
  })
}



//编辑不可发货原因列表
export function UpForbidLogistics(data) {
  return request({
      url: baseURL.baseURL + 'api/Adm_Order/UpForbidLogistics',
      method: 'post',
      data
  })
}



//物流围栏列表
export function GetCollectFenceList(params) {
  return request({
      url: baseURL.baseURL + 'api/Adm_Order/GetCollectFenceList',
      method: 'get',
      params
  })
}

//添加物流围栏
export function AddCollectFence(data) {
  return request({
      url: baseURL.baseURL + 'api/Adm_Order/AddCollectFence',
      method: 'post',
      data
  })
}


//编辑物流围栏
export function UpCollectFence(data) {
  return request({
      url: baseURL.baseURL + 'api/Adm_Order/UpCollectFence',
      method: 'post',
      data
  })
}



