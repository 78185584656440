<template>
  <div class="districon">
    <div class="btn">
      <el-button type="primary" @click="add">新增</el-button>
    </div>
    <div class="tablediv">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column fixed prop="name" align="center" label="平台名称">
        </el-table-column>
        <el-table-column prop="remarks" label="备注" align="center">
        </el-table-column>
        <el-table-column fixed="right" align="center" label="操作" width="300">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="edit(scope.row)"
              >编辑</el-button
            >
            <el-button type="text" size="small" @click="delets(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      title="添加平台"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-form
        :rules="rules"
        :model="ruleForm"
        ref="ruleForm"
        label-width="100px"
        class="frame"
      >
        <el-form-item label="平台名称" prop="name">
          <el-input maxlength="20" v-model="ruleForm.name"></el-input>
        </el-form-item>

        <el-form-item label="备注" prop="remark">
          <el-input v-model="ruleForm.remark"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            v-loading="querload"
            @click="addquery('ruleForm')"
            >确认</el-button
          >
          <el-button @click="handleClose">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <div class="block">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50]"
        :page-size="pagesize"
        layout="total, sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { ListPlatform, AddPlatform, UpPlatform } from "../../api/nologistics";
export default {
  data() {
    return {
      id: 0,
      edits: 0,
      querload: false,
      total: 0,
      tableData: [],
      currentPage: 1,
      pagesize: 10,
      dialogVisible: false,
      ruleForm: {
        name: "",
        remark: "",
      },
      rules: {
        name: [{ required: true, message: "请输入平台名称", trigger: "blur" }],
      },
    };
  },
  created() {
    this.getlist();
  },
  methods: {
    delets(row) {
      console.log(row);
      this.$confirm("是否删除该平台模板", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          console.log(111);
          row.isDelete = true;
          this.editUpPlatform(row);
          this.getlist();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    edit(row) {
      console.log(row);
      this.edits = 1;
      this.ruleForm.name = row.name;
      this.ruleForm.remark = row.remarks;
      this.id = row.platformId;
      this.dialogVisible = true;
    },

    editUpPlatform(data) {
      UpPlatform(data)
        .then((res) => {
          console.log(res);
          if (res.code == 10000) {
            this.querload = false;
            this.$message.success(res.msg);
            this.getlist();
            this.handleClose();
          } else {
            this.$message.error(res.msg);
            this.querload = false;
            this.getlist();
            this.handleClose();
          }
        })
        .catch((err) => {
          this.querload = false;
          this.$message.error(err.msg);
          this.getlist();
          this.handleClose();
        });
    },

    addAddPlatform(data) {
      AddPlatform(data)
        .then((res) => {
          console.log(res);
          if (res.code == 10000) {
            this.querload = false;
            this.dialogVisible = false;
            this.$message.success(res.msg);
            this.getlist();
            this.handleClose();
          } else {
            this.dialogVisible = false;
            this.querload = false;
            this.$message.error(res.msg);
            this.getlist();
            this.handleClose();
          }
        })
        .catch((err) => {});
    },

    addquery(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.querload = true;
          let data = {
            name: this.ruleForm.name,
            Remarks: this.ruleForm.remark,
          };
          if (this.edits == 1) {
            data.isDelete = false;
            data.platformId = this.id;
            this.editUpPlatform(data);
          } else {
            this.addAddPlatform(data);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    getlist() {
      let data = {
        pageIndex: this.currentPage,
        pageSiz: this.pagesize,
      };
      ListPlatform(data).then((res) => {
        console.log(res);
        this.tableData = res.data;
        this.total = res.total;
      });
    },

    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.currentPage = val;
      this.getlist();
    },
    handleCurrentChange(val) {
      this.pagesize = val;
      console.log(`当前页: ${val}`);
      this.getlist();
    },
    handleClose() {
      this.ruleForm = {
        name: "",
        remark: "",
      };

      this.dialogVisible = false;
    },
    add() {
      this.edits = 0;
      this.$nextTick(() => {
        this.$refs.ruleForm.clearValidate();
      });
      this.dialogVisible = true;
    },
    handleChange() {},
    delet(row) {
      this.$confirm("是否删除不可发货模板", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "删除不可发货模板成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style scoped>
.districon {
  width: 100%;
  height: 100%;
  background: white;
  margin-top: 10px;
}
.btn {
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  padding-top: 5px;
  float: right;
}
.tablediv {
  margin-top: 15px;
}
.block {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.footer {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
</style>